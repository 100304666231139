import store from "@/store";
import { appClient, apiClient } from "./clients";

const debug = process.env.NODE_ENV !== "production";
const getAuthToken = () => localStorage.getItem("_token") || null;
const getCsrfToken = () => document.head.querySelector('meta[name="csrf-token"]') || null;

/*
  Interceptors
 */

const successRequestHandler = (config) => {
  if (getAuthToken()) config.headers.Authorization = `Bearer ${getAuthToken()}`;
  if (getCsrfToken()) config.headers.common["X-CSRF-TOKEN"] = getCsrfToken().content;
  if (window.Echo) config.headers.common["X-Socket-Id"] = window.Echo.socketId();
  return config;
};

const errorRequestHandler = (error) => {
  if (debug) console.info("✉ REQ_ERR ", error);
  return Promise.reject({ ...error });
};

const successResponseHandler = (response) => response;
const errorResponseHandler = async (error) => {
  if (debug)
    console.info(
      "✉ RES_ERR ",
      error.message + " | ",
      error.response && error.response.data ? JSON.parse(error.response.data).message : null
    );
  if (error.config.url !== "/me" && error.response.status === 401) await store.dispatch("ResetAll");
  return Promise.reject({ ...error });
};

/*
  Transformers
 */
const transformResponse = (config) => config;
const transformRequest = (data, headers, isGetRequest = false) => {
  if (data instanceof FormData) {
    headers.common["Content-Type"] = "multipart/form-data";
    headers.post["Content-Type"] = "multipart/form-data";
    headers.put["Content-Type"] = "multipart/form-data";
    return data;
  }
  if ("_notify" in data) delete data._notify;
  if ("_silent" in data) delete data._silent;
  if ("_overlay" in data) delete data._overlay;
  if ("_forceload" in data) delete data._forceload;
  if ("_notifyError" in data) delete data._notifyError;
  return isGetRequest ? data : JSON.stringify(data);
};

class Http {
  constructor(base = "api") {
    this.client = base === "api" ? apiClient : appClient;
    this.client.interceptors.request.use(successRequestHandler, errorRequestHandler);
    this.client.interceptors.response.use(successResponseHandler, errorResponseHandler);
  }

  get(url, payload, config) {
    return this.client
      .get(url, {
        ...{
          params: transformRequest(payload, null, true),
          transformResponse: [transformResponse],
        },
        ...config,
      })
      .then((res) => {
        if ("responseType" in config) return res;
        res.data = JSON.parse(res.data);
        return res;
      })
      .catch((err) => {
        err.response.data = JSON.parse(err.response.data);
        return err.response;
      });
  }

  post(url, payload, config) {
    return this.client
      .post(url, payload, {
        ...{
          transformRequest: [transformRequest],
          transformResponse: [transformResponse],
        },
        ...config,
      })
      .then((res) => {
        if ("responseType" in config) return res;
        res.data = JSON.parse(res.data);
        return res;
      })
      .catch((err) => {
        err.response.data = JSON.parse(err.response.data);
        return err.response;
      });
  }

  put(url, payload, config) {
    return this.client
      .put(url, payload, {
        ...{
          transformRequest: [transformRequest],
          transformResponse: [transformResponse],
        },
        ...config,
      })
      .then((res) => {
        if ("responseType" in config) return res;
        res.data = JSON.parse(res.data);
        return res;
      })
      .catch((err) => {
        err.response.data = JSON.parse(err.response.data);
        return err.response;
      });
  }

  delete(url, payload) {
    return this.client
      .delete(url, payload, {
        transformRequest: [transformRequest],
        transformResponse: [transformResponse],
      })
      .then((res) => res)
      .catch((err) => {
        err.response.data = JSON.parse(err.response.data);
        return err.response;
      });
  }
}

const apiHttp = new Http("api");
const appHttp = new Http("app");
export { apiHttp, appHttp };
