import Vue from "vue";
import Vuex from "vuex";
import createLogger from "vuex/dist/logger";
import modules from "./modules";
import router from "@/router";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules,
  actions: {
    ResetStore({ commit }) {
      localStorage.removeItem("_token");
      Object.keys(modules).forEach((moduleName) => commit(`Reset${moduleName}`));
    },
    ResetAll({ dispatch }) {
      dispatch("ResetStore");
      router.push({ name: "login" }).catch(() => {});
    },
  },
  strict: debug,
  plugins: debug ? [createLogger()] : [], // set logger only for development
});
