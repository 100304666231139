<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col>
        <div class="d-flex flex-column justify-center align-center pa-5">
          <v-icon class="mb-2" color="secondaryText">mdi-cloud-outline</v-icon>
          <div class="text-body-2 text--secondary">{{ getText }}</div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "SkeletonEmptyList",
  props: ["text"],
  computed: {
    getText() {
      return this.hasText ? this.text : "No Data Found";
    },
    hasText() {
      return !!this.text;
    },
  },
};
</script>

<style scoped></style>
