import isEmpty from "lodash/isEmpty";

const initialState = () => ({
  aircraftModel: {},
  aircraftModels: [],
  newModel: {},
});

export default {
  state: initialState(),
  actions: {
    async loadAircraftModels({ commit, state }, payload = {}) {
      if (!("_forceload" in payload) && !isEmpty(state.aircraftModels)) return;
      let res = await this._vm.$api.get("AircraftModel", payload);
      if (res.status === 200) {
        commit("setAircraftModels", res.data.data);
      }
    },

    async loadAircraftModel({ commit }, payload = {}) {
      let res = await this._vm.$api.get("AircraftModel", payload);
      if (res.status === 200) {
        commit("setAircraftModel", res.data.data);
      }
    },
  },
  mutations: {
    setAircraftModel(state, data) {
      state.aircraftModel = data;
    },
    setAircraftModels(state, data) {
      state.aircraftModels = data;
    },
    ResetAircraftModel(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getAircraftModel: (state) => state.aircraftModel,
    getAircraftModels: (state) => state.aircraftModels,
  },
};
