import { groupBy, orderBy } from "lodash";
import moment from "moment";

const initialState = () => ({
  history: [],
  historyPage: 1,
  historyLastPage: 0,
});

export default {
  state: initialState(),
  actions: {
    async loadHistory1({ commit, state }, payload = {}) {
      if (state.historyPage > state.historyLastPage && state.historyLastPage !== 0) return 0;
      let res = await this._vm.$api.get("History", {
        ...{ page: state.historyPage },
        ...payload,
      });
      if (res.status === 200) {
        commit("addMoreHistory", res.data.data);
        commit("setHistoryPage", state.historyPage + 1);
        commit("setHistoryLastPage", res.data.meta.last_page);
        return res.data.data.length;
      }
      return -1;
    },
    async loadHistory({ commit }, payload = {}) {
      let res = await this._vm.$api.get("History", payload);
      if (res.status === 200) {
        commit("setHistory", res.data.data);
        return res.data.meta.last_page;
      }
      return 0;
    },
  },
  mutations: {
    setHistoryPage(state, data) {
      state.historyPage = data;
    },
    setHistoryLastPage(state, data) {
      state.historyLastPage = data;
    },
    setHistory(state, data) {
      state.history = data;
    },
    upsetHistory(state, data) {
      if (state.history.length === 0) return;
      const obj = state.history.find((o) => o.id === data.id);
      obj ? Object.assign(obj, data) : state.history.push(data);
    },
    addMoreHistory(state, data) {
      data.forEach((eq) => state.history.push(eq));
    },
    ResetHistory(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getHistory: (state) => state.history,
    getGroupedHistory(state) {
      return groupBy(orderBy(state.history, ["created_at"], ["desc"]), (log) =>
        moment(log.created_at).format("YYYY-MM-DD")
      );
    },
  },
};
