import Vue from "vue";
import store from "./store";
import VueMeta from "vue-meta";
import Echo from "laravel-echo";
import VueGtag from "vue-gtag";
import VueMoment from "vue-moment";
import moment from "moment-timezone";
import InfiniteLoading from "vue-infinite-loading";
import axios from "axios";
import isEmpty from "lodash/isEmpty";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { Api, GlobalStore, Loader, Notifier } from "./services";
import SkeletonEmptyList from "@/components/skeletons/SkeletonEmptyList";

// Import Stylesheets
import "./theme/_global.scss";

if (!isEmpty(process.env.VUE_APP_BUGSNAG_API_KEY)) {
  Bugsnag.start({
    apiKey: process.env.VUE_APP_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    appType: "client",
    releaseStage: process.env.NODE_ENV,
    enabledReleaseStages: ["production", "development"],
    user: store.getters.getBugsnagUser,
  });
  const bugsnagVue = Bugsnag.getPlugin("vue");
  bugsnagVue.installVueErrorHandler(Vue);
}

// Register Plugins
Vue.use(Notifier, { store });
Vue.use(Loader, { store });
Vue.use(GlobalStore, { store });
Vue.use(Api, { store });
Vue.use(VueMeta);
Vue.use(VueMoment, { moment });
Vue.use(InfiniteLoading, {
  props: {
    spinner: "spiral",
  },
  slots: {
    noResults: SkeletonEmptyList,
    noMore: "End of results!",
  },
});
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_GTAG_ID,
  },
  bootstrap: true,
});

// Register global components
Vue.component("Logo", () => import(/* webpackChunkName: "global" */ "@/components/Logo"));
Vue.component("Status", () => import(/* webpackChunkName: "global" */ "@/components/Status"));
Vue.component("ListDetail", () => import(/* webpackChunkName: "global" */ "@/components/ListDetail"));
Vue.component("OrganizationSelect", () => import(/* webpackChunkName: "org-tree" */ "@/components/OrganizationSelect"));
Vue.component("OrganizationTree", () => import(/* webpackChunkName: "org-tree" */ "@/components/OrganizationTree"));

window.Pusher = require("pusher-js");
window.Echo = new Echo({
  broadcaster: "pusher",
  key: process.env.VUE_APP_PUSHER_APP_KEY,
  wsHost: process.env.VUE_APP_SOCKET_URL,
  wsPort: "80",
  wssPort: "443",
  forceTLS: false,
  encrypted: true,
  disableStats: true,
  enabledTransports: ["ws", "wss"],
  // eslint-disable-next-line
  authorizer: (channel, options) => {
    return {
      authorize: (socketId, callback) => {
        axios
          .post(
            "/api/broadcasting/auth",
            {
              socket_id: socketId,
              channel_name: channel.name,
            },
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_token"),
              },
            }
          )
          .then((response) => {
            callback(false, response.data);
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    };
  },
});
