const initialState = () => ({
  rules: {
    required: (value) => !!value || "Required.",
    requiredArray: (value) => !!value.length || "Required.",
    number: (v) => !isNaN(v) || "Not a numeric value",
    nonZeroNumber: (v) => v > 0 || "Amount should be non-zero",
    agree: (v) => !!v || "You must agree to continue!",
    min: (v) => v.length >= 8 || "Min 8 characters",
    maxPax: (v) => v <= 500 || "Passenger limit: 1-500",
    emailMatch: () => "The e-mail and password you entered don't match",
    allowedAttachments2: () =>
      "image/*,video/*,text/*,application/pdf,application/msword,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    allowedAttachments: () =>
      "*.jpg,*.jpeg,*.png,*.bmp,*.svg,*.gif,*.doc,*.docx,*.mp4,*.webp,*.webm,*.ppt,*.pptx,*.csv,*.txt",
    attachmentNumber: (v) => v.length <= 10 || "Only 10 attachments allowed at a time",
    positiveNumber: (v) => {
      if (v) {
        // if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 10000) return true;
      }
      return "Number should be valid";
    },
    email: (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return pattern.test(value) || "Invalid e-mail.";
    },
  },
});

export const FormRules = {
  state: initialState(),
  actions: {},
  mutations: {
    ResetFormRules(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },
  getters: {
    getRules: (state) => state.rules,
  },
};
