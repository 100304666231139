import { groupBy, orderBy } from "lodash";
import moment from "moment";

const initialState = () => ({
  chat: [],
  chatErrors: {},
});

export default {
  state: initialState(),
  actions: {
    async sendMessage({ dispatch }, payload = {}) {
      if (!(payload instanceof FormData)) dispatch("upsetChatAction", payload);
      let res = await this._vm.$api.post("Chat", payload);
      if (res.status === 201) {
        dispatch("upsetChatAction", res.data.data);
      } else if (res.status === 422) {
        this._vm.$notify({ message: res.data.message, type: "error" });
      }
      return res.status === 201;
    },

    async readMessage({ getters }, payload) {
      if (getters.getActiveEnquiry === payload.id && getters.getEnquiry.user_id === getters.getAuthUser.id)
        await this._vm.$api.get("MessageRead", payload);
    },

    upsetChatAction({ commit, getters }, payload) {
      commit("updateEnquiryUpdatedAt", payload.enquiry_id);
      getters.getActiveEnquiry === payload.enquiry_id
        ? commit("upsetChatMutation", payload)
        : commit("updateEnquiryUnreadCount", payload.enquiry_id);
    },
    deleteChatAction({ commit, getters }, payload) {
      commit("updateEnquiryUpdatedAt", payload.enquiry_id);
      getters.getActiveEnquiry === payload.enquiry_id
        ? commit("deleteChatMutation", payload)
        : commit("updateEnquiryUnreadCount", payload.enquiry_id);
    },
    updateSingleChatAction({ commit, getters }, payload) {
      commit("updateEnquiryUpdatedAt", payload.enquiry_id);
      getters.getActiveEnquiry === payload.enquiry_id
        ? commit("setSingleChat", payload)
        : commit("updateEnquiryUnreadCount", payload.enquiry_id);
    },
    async deleteMessage({ commit }, payload = {}) {
      let chatId = payload.id;
      let res = await this._vm.$api.delete("Chat", payload);
      if (res.status === 200) {
        commit("setDeleteChat", chatId);
      } else if (res.status === 422) {
        this._vm.$notify({ message: res.data.message, type: "error" });
      }
    },
    async updateMessage({ commit }, payload = {}) {
      let res = await this._vm.$api.put("Chat", payload);

      if (res.status === 200) {
        commit("setSingleChat", res.data);
      } else if (res.status === 422) {
        this._vm.$notify({ message: res.data.message, type: "error" });
      }
    },
  },
  mutations: {
    setChat(state, data) {
      state.chat = data;
    },
    setDeleteChat(state, id) {
      state.chat = state.chat.filter((chat) => chat.id !== id);
    },
    setSingleChat(state, data) {
      const singleMessage = state.chat.find((chat) => chat.id === data.id);
      if (singleMessage) {
        Object.assign(singleMessage, data);
      }
    },
    upsetChatMutation(state, data) {
      const obj = state.chat.find((ch) => parseInt(ch.timestamp) === parseInt(data.timestamp));
      obj ? Object.assign(obj, data) : state.chat.push(data);
    },
    deleteChatMutation(state, data) {
      state.chat = state.chat.filter((chat) => chat.id !== data.id);
    },
    markChatAsRead(state, ids) {
      let chats = state.chat.filter((c) => ids.includes(c.id));
      chats.forEach((chat) => {
        chat.read_by_owner = true;
      });
    },
    ResetChat(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getChat: (state) => orderBy(state.chat, ["created_at"], ["asc"]),
    getGroupedChat: (state) =>
      groupBy(orderBy(state.chat, ["created_at"], ["asc"]), (chat) => moment(chat.created_at).format("YYYY-MM-DD")),
  },
};
