import { NotifierStore } from "./store";

const Notifier = {
  install: (Vue, { store }) => {
    if (!store) throw new Error("Please provide vuex store.");
    if (!store.hasModule("Notifier")) store.registerModule("Notifier", NotifierStore);
    Vue.component("Notifier", () => import(/* webpackChunkName: "global" */ "@/services/notifier/Notifier"));
    Vue.prototype.$notify = function ({ message = "", type = "success" }) {
      store.commit("setNotifier", { message, type });
    };
  },
};
export { Notifier };
