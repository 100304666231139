import { Auth } from "../models";
import router from "@/router";

const initialState = () => ({
  authenticated: false,
  authErrors: {},
  passcodeError: "",
  authUser: new Auth(),
  token: localStorage.getItem("_token") || null,
  redirect: null,
});

export default {
  state: initialState(),
  actions: {
    async authCheck({ commit }) {
      let res = await this._vm.$api.get("AuthCheck", {});
      if (res.status === 200) commit("setAuthUser", res.data.data);
      return res.status === 200;
    },

    async login({ state, commit }, payload = {}) {
      let res = await this._vm.$api.post("Login", payload);
      commit("setAuthErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200) {
        commit("setAuth", res.data.data);
        if (state.redirect) {
          const redirect = state.redirect;
          commit("setRedirect", null);
          await router.push(redirect);
        } else {
          let match = document.cookie.match(new RegExp("(^| )" + "legs" + "=([^;]+)"));
          if (match || "verified" in router.currentRoute.query) {
            router.push("/request/add");
            return;
          }
          await router.push({ name: "requests" });
        }
      }
    },

    async impersonate({ state, commit }, payload = {}) {
      let res = await this._vm.$api.post("Impersonate", payload);
      commit("setAuthErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200) {
        commit("setAuth", res.data.data);
        if (state.redirect) {
          const redirect = state.redirect;
          commit("setRedirect", null);
          await router.push(redirect);
        } else {
          let match = document.cookie.match(new RegExp("(^| )" + "legs" + "=([^;]+)"));
          if (match || "verified" in router.currentRoute.query) {
            router.push("/request/add");
            return;
          }
          await router.push({ name: "requests" });
        }
      }
    },

    async guestLogin({ commit }, payload = {}) {
      let res = await this._vm.$api.post("GLogin", payload);
      commit("setAuthErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200) {
        if (router.currentRoute.name !== "verify-login") {
          await router.push({ name: "verify-login", params: { email: res.data.data.email } });
        }
      }
    },

    async logout({ commit, dispatch }, payload = { _notify: true }) {
      let res = await this._vm.$api.post("Logout", payload);
      commit("setAuthErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200) {
        await dispatch("ResetAll");
      }
    },

    async signup({ commit }, payload = {}) {
      let res = await this._vm.$api.post("Signup", payload);
      commit("setAuthErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200) {
        if (router.currentRoute.name !== "verify-email") {
          await router.push({ name: "verify-email", params: { email: payload.email } });
        }
      } else if (res.status === 201) {
        commit("setAuth", res.data.data.user);
        return "payment" in res.data.data ? res.data.data.payment : true;
      }
      return false;
    },

    async forgotPassword({ commit }, payload = {}) {
      let res = await this._vm.$api.post("ForgotPassword", payload);
      commit("setAuthErrors", "errors" in res.data ? res.data.errors : {});
      return res.status === 200;
    },

    async resendVerificationEmail({ commit }, payload = {}) {
      let res = await this._vm.$api.post("ResendEmail", payload);
      commit("setAuthErrors", "errors" in res.data ? res.data.errors : {});
      return res.status === 200;
    },

    async verifyPasscode({ state, commit }, payload = {}) {
      let res = await this._vm.$api.post("VerifyPasscode", payload);
      commit("setPasscodeError", "error" in res.data ? res.data.error : "");
      if (res.status === 200) {
        commit("setAuth", res.data.data);
        if (state.redirect) {
          const redirect = state.redirect;
          commit("setRedirect", null);
          await router.push(redirect);
        } else {
          let match = document.cookie.match(new RegExp("(^| )" + "legs" + "=([^;]+)"));
          if (match || "verified" in router.currentRoute.query) {
            router.push("/request/add");
            return;
          }
          await router.push({ name: "requests" });
        }
      }
      return res.status === 200;
    },

    async updatePassword({ commit }, payload = {}) {
      let res = await this._vm.$api.post("UpdatePassword", payload);
      commit("setAuthErrors", "error" in res.data ? res.data.error : "");
      return res.status === 200;
    },

    async chatLogin({ commit }, payload = {}) {
      let res = await this._vm.$api.post("LoginChat", payload);
      commit("setAuthErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200) {
        commit("setAuth", res.data.data);
      }
      return res.status !== 200;
    },
  },

  mutations: {
    setAuth(state, data) {
      state.token = data.token;
      localStorage.setItem("_token", data.token);
      delete data.token;
      state.authUser = data;
      state.authenticated = true;
    },
    setAuthUser(state, user) {
      state.authUser = user;
      state.authenticated = true;
    },
    setEliteAuthUser(state) {
      state.authUser.isElite = 0;
    },
    setAuthErrors(state, errors) {
      state.authErrors = errors;
    },
    setPasscodeError(state, error) {
      state.passcodeError = error;
    },
    setRedirect(state, data) {
      state.redirect = data;
    },
    ResetAuth(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    isAuthenticated: (state) => state.authenticated,
    getAuthErrors: (state) => state.authErrors,
    getPasscodeError: (state) => state.passcodeError,
    getAuthStatus: (state) => !!state.authUser.id || state.token,
    getAuthToken: (state) => state.token,
    getAuthUser: (state) => state.authUser,
    getAccess: (state) => state.authUser.access,
    getRedirect: (state) => state.redirect,
    hasAccessTo: (state) => (id) => state.authUser.access.includes(id),
    isSales: (state) => state.authUser.user_type === "sales",
    isAgent: (state) => state.authUser.user_type === "agent",
    isAdmin: (state) => state.authUser.user_type === "admin",
    isConsumer: (state) => state.authUser.user_type === "consumer",
    isMaster: (state) => state.authUser.user_role === "master",
    isRegular: (state) => state.authUser.user_role === "regular",
    isBackendUser: (state) => state.authUser.user_type === "admin" || state.authUser.user_type === "sales",
    isMasterAgent: (state) => state.authUser.user_role === "master" && state.authUser.user_type === "agent",
    isMasterAndAdmin: (state) =>
      (state.authUser.user_role === "master" && state.authUser.user_type === "agent") ||
      state.authUser.user_type === "admin" ||
      state.authUser.user_type === "sales",
    getBugsnagUser: (state) =>
      state.authenticated
        ? {
            id: state.authUser.id,
            name: `${state.authUser.name} (${state.authUser.user_type}-${state.authUser.user_role})`,
            email: state.authUser.email,
          }
        : {},
  },
};
