import { LoaderStore } from "./store";

const Loader = {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error("Please provide vuex store.");
    }
    if (!store.hasModule("Loader")) store.registerModule("Loader", LoaderStore);
    store.subscribeAction({
      before: (action) => {
        if (action.payload && "_overlay" in action.payload) store.commit("OVERLAY_START", action.type);
        if (action.payload && !("_silent" in action.payload) && action.type !== "sendMessage")
          store.commit("LOADER_START", action.type);
        else if (!action.payload) store.commit("LOADER_START", action.type);
      },
      after: (action) => {
        store.commit("LOADER_END", action.type);
        if (action.payload && "_overlay" in action.payload) store.commit("OVERLAY_END", action.type);
      },
      error: (action) => {
        store.commit("LOADER_END", action.type);
        if (action.payload && "_overlay" in action.payload) store.commit("OVERLAY_END", action.type);
      },
    });
    Vue.prototype.$loading = function (waiter = null) {
      return store.getters.getLoading(waiter);
    };

    Vue.prototype.$overlay = function (waiter = null) {
      return store.getters.getOverlay(waiter);
    };
  },
};
export { Loader };
