// import Model from "../models/Model";
import isEmpty from "lodash/isEmpty";

const initialState = () => ({
  model: {},
  models: [],
  newModel: {},
});

export default {
  state: initialState(),
  actions: {
    async loadModels({ commit, state }, payload = {}) {
      if (!("_forceload" in payload) && !isEmpty(state.models)) return;
      let res = await this._vm.$api.get("Model", payload);
      if (res.status === 200) {
        commit("setModels", res.data.data);
      }
    },

    async loadModel({ commit }, payload = {}) {
      let res = await this._vm.$api.get("Model", payload);
      if (res.status === 200) {
        commit("setModel", res.data.data);
      }
    },

    async addModel({ commit, state }, payload = {}) {
      let res = await this._vm.$api.post("Model", {
        ...state.newModel,
        ...payload,
      });
      if (res.status === 200) {
        commit("setModel", res.data.data);
      }
    },
  },
  mutations: {
    setModel(state, data) {
      state.model = data;
    },
    setModels(state, data) {
      state.models = data;
    },
    ResetModel(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getModel: (state) => state.model,
    getModels: (state) => state.models,
    getModelsByStatus: (state) => (status) => state.models.filter((model) => model.status === status),
  },
};
