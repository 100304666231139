require("./bootstrap");

import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./theme/vuetify";
import "./services/filters/global";
import mixins from "./plugins/mixins";

Vue.mixin(mixins);

Vue.config.productionTip = false;
Vue.config.performance = true;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
