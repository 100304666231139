import isEmpty from "lodash/isEmpty";

const initialState = () => ({
  page: {},
  pages: [],
});

export default {
  state: initialState(),
  actions: {
    async loadPages({ commit, state }, payload = {}) {
      if (!("_forceload" in payload) && !isEmpty(state.pages)) return;
      let res = await this._vm.$api.get("KnowledgeBase", payload);
      if (res.status === 200) {
        commit("setPages", res.data.data);
      }
    },
    async loadPage({ commit, getters }, payload = {}) {
      if (payload.id === getters.getActivePage && !("_forceload" in payload)) return;
      let res = await this._vm.$api.get("KnowledgeBase", payload);
      if (res.status === 200) {
        commit("setPage", res.data.data);
      }
    },
  },
  mutations: {
    setPage(state, data) {
      state.page = data;
    },
    setPages(state, data) {
      state.pages = data;
    },
    ResetKnowledgeBase(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getPage: (state) => state.page,
    getPages: (state) => state.pages,
    getActivePage: (state) => state.page.id ?? null,
  },
};
