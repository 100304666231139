import Vue from "vue";
import VueRouter from "vue-router";
import Meta from "vue-meta";
import store from "@/store";

Vue.use(VueRouter);
Vue.use(Meta);

const routes = [
  {
    path: "/mobile",
    name: "mobile-home",
    redirect: { name: "mobile-requests" },
    component: () => import(/* webpackChunkName: "mobile" */ "../views/mobile/MobileHome"),
    children: [
      {
        path: "requests",
        name: "mobile-requests",
        component: () => import(/* webpackChunkName: "mobile-requests" */ "../views/mobile/requests/MobileRequests"),
      },
      {
        path: "new-request",
        name: "mobile-new-request",
        component: () =>
          import(/* webpackChunkName: "mobile-new-request" */ "../views/mobile/requests/MobileNewRequest"),
      },
      {
        path: "manage",
        name: "mobile-manage",
        component: () => import(/* webpackChunkName: "mobile-manage" */ "../views/mobile/manage/MobileManage"),
      },
      {
        path: "settings",
        name: "mobile-settings",
        component: () => import(/* webpackChunkName: "mobile-settings" */ "../views/mobile/MobileSettings"),
      },
    ],
  },
  {
    path: "/:token/:email/:uuid/chat/:enquiryId",
    name: "chat",
    props: true,
    component: () => import(/* webpackChunkName: "chat" */ "../views/chat/ChatSolo"),
  },
  {
    path: "/design",
    name: "design",
    component: () => import(/* webpackChunkName: "ui" */ "../views/Design"),
  },
  {
    path: "/membership",
    name: "membership",
    redirect: { name: "membership-signup" },
    component: () => import(/* webpackChunkName: "membership" */ "../views/auth/Membership"),
    children: [
      {
        path: "register",
        name: "membership-signup",
        component: () => import(/* webpackChunkName: "membership-signup" */ "../views/auth/MembershipSignup"),
        meta: {
          hideForAuth: true,
        },
      },
      {
        path: "success",
        name: "membership-success",
        component: () => import(/* webpackChunkName: "membership-success" */ "../views/auth/MembershipSuccess"),
        // beforeEnter: (to, from, next) => {
        //   let referrer = document.referrer;
        //   if (referrer ? referrer.includes("checkout.stripe.com") : false) next();
        //   else next({ name: "home" });
        // },
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "error",
        name: "membership-error",
        component: () => import(/* webpackChunkName: "membership-error" */ "../views/auth/MembershipSuccess"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/auth",
    name: "auth",
    redirect: { name: "login" },
    component: () => import(/* webpackChunkName: "auth" */ "../views/AuthHome"),
    meta: {
      hideForAuth: true,
    },
    children: [
      {
        path: "login",
        name: "login",
        component: () => import(/* webpackChunkName: "auth-login" */ "../views/auth/Login"),
      },
      {
        path: "quick-login",
        name: "quick-login",
        component: () => import(/* webpackChunkName: "auth-quick-login" */ "../views/auth/QuickLogin"),
      },
      {
        path: "register/:type?",
        name: "register",
        props: true,
        component: () => import(/* webpackChunkName: "auth-register" */ "../views/auth/SelectMembership"),
      },
      {
        path: "impersonate",
        name: "impersonate",
        component: () => import(/* webpackChunkName: "auth-impersonate" */ "../views/auth/Login"),
      },
      {
        path: "otp/:email?",
        name: "verify-login",
        props: true,
        component: () => import(/* webpackChunkName: "auth-verify-login" */ "../views/auth/VerifyLogin"),
      },
      {
        path: "verify/:email?",
        name: "verify-email",
        props: true,
        component: () => import(/* webpackChunkName: "auth-verify-email" */ "../views/auth/VerifyEmail"),
      },
    ],
  },
  {
    path: "/",
    redirect: { name: "requests" },
    name: "home",
    component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "requests",
        name: "requests",
        component: () => import(/* webpackChunkName: "requests" */ "../views/requests/Requests"),
      },
      {
        path: "request/:type",
        name: "request",
        component: () => import(/* webpackChunkName: "requests" */ "../views/requests/RequestsV3"),
        props: true,
      },
      {
        path: "manage",
        name: "settings",
        redirect: { name: "manage-organizations" },
        component: () => import(/* webpackChunkName: "manage" */ "../views/manage/Settings"),
        children: [
          {
            path: "organizations",
            name: "manage-organizations",
            component: () => import(/* webpackChunkName: "organizations" */ "../views/manage/Organizations"),
          },
          {
            path: "users",
            name: "manage-users",
            component: () => import(/* webpackChunkName: "users" */ "../views/manage/Users"),
          },
          {
            path: "logs",
            name: "manage-logs",
            component: () => import(/* webpackChunkName: "logs" */ "../views/manage/Logs"),
          },
          {
            path: "profile",
            name: "manage-profile",
            component: () => import(/* webpackChunkName: "profile" */ "../views/manage/Profile"),
          },
          {
            path: "whitelabels",
            name: "manage-whitelabels",
            component: () => import(/* webpackChunkName: "whitelabels" */ "../views/manage/Whitelabels"),
          },
        ],
      },
      {
        path: "manage-content",
        name: "manage-content",
        redirect: { name: "manage-knowledge-base" },
        component: () => import(/* webpackChunkName: "manage-content" */ "../views/content/ManageContentHome"),
        children: [
          {
            path: "knowledge-base",
            name: "manage-knowledge-base",
            component: () =>
              import(/* webpackChunkName: "manage-knowledge-base" */ "../views/content/ManageKnowledgeBase"),
          },
          {
            path: "airports",
            name: "manage-airports",
            component: () => import(/* webpackChunkName: "manage-airports" */ "../views/content/ManageAirports"),
          },
          {
            path: "aircraft-models",
            name: "manage-aircraft-models",
            component: () =>
              import(/* webpackChunkName: "manage-aircraft-models" */ "../views/content/ManageAircraftModels"),
          },
        ],
      },
      {
        path: "knowledge-base",
        name: "knowledge-base",
        component: () => import(/* webpackChunkName: "knowledge-base" */ "../views/kbase/KnowledgeBase"),
      },
    ],
  },
  {
    path: "*",
    redirect: { name: "login" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (from.name === null && to.name !== "chat") {
    let auth = await store.dispatch("authCheck");
    if (!auth) await store.dispatch("ResetStore");
  }

  if (to.matched.some((record) => record.meta.requiresAuth) && !store.getters.getAuthStatus) {
    next({ name: "login", query: { redirect: to.fullPath } });
  } else {
    next();
  }

  if (to.matched.some((record) => record.meta.hideForAuth) && store.getters.getAuthStatus) {
    let match = document.cookie.match(new RegExp("(^| )" + "legs" + "=([^;]+)"));
    if (match) {
      router.push("/request/add");
      return;
    } else {
      next({ name: "home" });
    }
  } else {
    next();
  }
});

export default router;
