const mutations = {
  LOADER_START: "LOADER_START",
  LOADER_END: "LOADER_END",
  OVERLAY_START: "OVERLAY_START",
  OVERLAY_END: "OVERLAY_END",
  PROGRESS: "LOADER_PROGRESS",
};

const initialState = () => ({
  overlay: [],
  loading: [],
  progress: {},
});

export const LoaderStore = {
  state: initialState(),
  actions: {},
  mutations: {
    [mutations.LOADER_START](state, waiter) {
      state.loading.push(waiter);
    },
    [mutations.LOADER_END](state, waiter) {
      let index = state.loading.findIndex((a) => a === waiter);
      if (index > -1) state.loading.splice(index, 1);
    },
    [mutations.OVERLAY_START](state, waiter) {
      state.overlay.push(waiter);
    },
    [mutations.OVERLAY_END](state, waiter) {
      let index = state.overlay.findIndex((a) => a === waiter);
      if (index > -1) state.overlay.splice(index, 1);
    },
  },
  getters: {
    getOverlay:
      (state) =>
      (waiter = null) =>
        waiter ? state.overlay.includes(waiter) : state.overlay.length > 0,
    getLoading:
      (state) =>
      (waiter = null) =>
        waiter ? state.loading.includes(waiter) : state.loading.length > 0,
  },
};
