import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import light from "./theme-light.js";

Vue.use(Vuetify);

export default new Vuetify({
  treeShake: true,
  theme: {
    options: {
      customProperties: true,
    },
    themes: { light },
  },
});
