import axios from "axios";
import qs from "qs";

const config = {
  headers: {
    common: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "X-Requested-With": "XMLHttpRequest",
    },
    post: {
      "Content-Type": "application/json",
    },
    patch: {
      "Content-Type": "application/json",
    },
    put: {
      "Content-Type": "application/json",
    },
  },

  withCredentials: false,
  responseType: "json",
  responseEncoding: "utf8",
  xsrfCookieName: "XSRF-TOKEN",
  xsrfHeaderName: "X-XSRF-TOKEN",
  timeout: 0,
  // maxContentLength: 2000,
  // maxBodyLength: 2000,
  paramsSerializer: function (params) {
    return qs.stringify(params);
  },
  validateStatus: function (status) {
    return status >= 200 && status < 300;
  },
};

export const appClient = axios.create({ ...{ baseURL: process.env.VUE_APP_URL }, ...config });
export const apiClient = axios.create({ ...{ baseURL: process.env.VUE_APP_API_URL }, ...config });
