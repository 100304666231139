import { FormRules } from "./form-rules.store";
import { Config } from "./config.store";

const GlobalStore = {
  install: (Vue, { store }) => {
    if (!store) {
      throw new Error("Please provide vuex store.");
    }
    if (!store.hasModule("FormRules")) store.registerModule("FormRules", FormRules);
    if (!store.hasModule("Config")) store.registerModule("Config", Config);
    Vue.prototype.$eventBus = new Vue();
  },
};
export { GlobalStore };
