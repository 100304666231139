<template>
  <v-app>
    <v-overlay absolute :value="$overlay()" z-index="10">
      <logo theme="dark" :width="160" />
    </v-overlay>
    <v-progress-linear
      :active="$loading()"
      color="primary"
      fixed
      height="5px"
      indeterminate
      style="z-index: 500"
      top
    ></v-progress-linear>
    <notifier></notifier>
    <v-main class="fill-height">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",
  metaInfo: {
    title: "Home",
    titleTemplate: "JetClass | %s ",
  },
  data: () => ({}),
};
</script>
