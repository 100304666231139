import isEmpty from "lodash/isEmpty";

const initialState = () => ({
  user: {},
  users: [],
  userErrors: {},
});

export default {
  state: initialState(),
  actions: {
    async loadUser({ commit, getters }, payload = {}) {
      if (payload.id === getters.getActiveUser && !("_forceload" in payload)) return;
      let res = await this._vm.$api.get("User", payload);
      if (res.status === 200) {
        commit("setUser", res.data.data);
      }
    },

    async loadUsers({ commit, state }, payload = {}) {
      if (!("_forceload" in payload) && !isEmpty(state.users)) return;
      let res = await this._vm.$api.get("User", payload);
      if (res.status === 200) {
        commit("setUsers", res.data.data);
      }
    },

    // eslint-disable-next-line no-empty-pattern
    async fetchUsers({}, payload = {}) {
      let res = await this._vm.$api.get("User", payload);
      return res.status === 200 ? res.data : [];
    },

    async addUser({ commit }, payload = {}) {
      let res = await this._vm.$api.post("User", payload);
      commit("setUserErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200 || res.status === 201) {
        commit("setUsers", res.data.data.users);
        commit("setLocations", res.data.data.locations);
      }
      return res.status === 200 || res.status === 201;
    },
    async editUser({ commit }, payload = {}) {
      let res = await this._vm.$api.put("User", payload);
      commit("setUserErrors", "errors" in res.data ? res.data.errors : {});
      if (res.status === 200 || res.status === 201) {
        commit("setUser", res.data.data);
      }
      return res.status === 200 || res.status === 201;
    },
  },
  mutations: {
    setUser(state, data) {
      state.user = data;
    },
    setUsers(state, data) {
      state.users = data;
    },
    setUserErrors(state, data) {
      state.userErrors = data;
    },
    upsetUsers(state, data) {
      const obj = state.users.find((o) => o.id === data.id);
      obj ? Object.assign(obj, data) : state.users.push(data);
    },
    ResetUser(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getUser: (state) => state.user,
    getUsers: (state) => state.users.filter((o) => o.user_type !== "admin"),
    getActiveUser: (state) => (state.user.id ? state.user.id : null),
    getUsersWithAccess: (state) => (id) => state.users.filter((o) => o.access.includes(id) && o.user_type !== "admin"),
    getUserErrors: (state) => state.userErrors,
  },
};
