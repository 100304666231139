import { decode } from "js-base64";

let mixins = {
  methods: {
    queryParamMixin(query = "") {
      try {
        query = decode(Object.entries(query)[0][0] + "=");

        let data = {};
        let pairs = (query[0] === "?" ? query.substr(1) : query).split("&");
        for (let i = 0; i < pairs.length; i++) {
          let pair = pairs[i].split("=");
          data[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
        }
        return data;
      } catch (e) {
        if (process.env.NODE_ENV === "development") {
          console.log(e);
        }
      }
    },
  },
};
export default mixins;
