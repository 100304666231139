import sortBy from "lodash/sortBy";
import {
  enquiryStatus,
  offerStatus,
  enquirySortOptions,
  timeOptions,
  userRoles,
  userTypes,
  organizationTypes,
  offerSortOptions,
} from "./constants";

const initialState = () => ({
  enquiryStatus: enquiryStatus,
  offerStatus: offerStatus,
  enquirySortOptions: enquirySortOptions,
  offerSortOptions: offerSortOptions,
  timeOptions: timeOptions,
  userTypes: userTypes,
  userRoles: userRoles,
  organizationTypes: organizationTypes,
  aircraftTypes: [],
});

export const Config = {
  state: initialState(),
  actions: {
    async loadConfig({ commit }, payload = {}) {
      if (!("_forceload" in payload)) return;
      let res = await this._vm.$api.get("Config", {});
      commit("setConfig", { state: "aircraftTypes", data: res.data.data.aircraft_types });
      commit("setHierarchy", res.data.data.hierarchy);
      commit("setOrganizations", res.data.data.organizations);
      commit("setEnquiryBookmarks", res.data.data.bookmarks_enquiry);
      commit("setOfferBookmarks", res.data.data.bookmarks_offer);
    },

    // eslint-disable-next-line no-empty-pattern
    async loadAirports({}, payload = {}) {
      let res = await this._vm.$api.get("Airport", payload);
      if (res.status) return res.data.data;
      return [];
    },
  },
  mutations: {
    setConfig(state, data) {
      state[data.state] = data.data;
    },
    ResetConfig(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getUserTypes: (state) => state.userTypes,
    getUserRoles: (state) => state.userRoles,
    getOrganizationTypes: (state) => state.organizationTypes,
    getEnquiryStatusList: (state) => state.enquiryStatus,
    getOfferStatusList: (state) => state.offerStatus,
    getEnquirySortOptions: (state) => state.enquirySortOptions,
    getOfferSortOptions: (state) => state.offerSortOptions,
    getTimeOptions: (state) => state.timeOptions,
    // getAircraftTypes: (state) => sortBy(state.aircraftTypes, ["position", "name"]),
    getAircraftTypes: (state) => state.aircraftTypes,
    getAirports: (state) => sortBy(state.airports, ["name", "city"]),
    getCountries: (state) => state.countries,
    getRegions: (state) => state.regions,
    getCurrencies: (state) => state.currencies,
    getAircraftTypeById: (state) => (id) => state.aircraftTypes.find((type) => type.id === id),
    getAircraftTypeByIds: (state) => (ids) =>
      state.aircraftTypes.filter((type) => {
        for (var key in ids) {
          if (type.id === ids[key]) return true;
        }
        return false;
      }),
  },
};
