import Vue from "vue";
import moment from "moment";

Vue.filter("status", function (status = "") {
  switch (status) {
    case "awaiting":
      return "Awaiting";
    case "accepted":
      return "Accepted";
    case "cancelled":
      return "Cancelled";
    case "expired":
      return "Expired";
    case "awaiting_offers":
      return "Awaiting Offers";
    case "working_on_offers":
      return "Working on Offers";
    case "awaiting_feedback":
      return "Awaiting Feedback";
    case "offer_confirmed":
      return "Offer Confirmed";
    case "flight_confirmed":
      return "Flight Confirmed";
    case "flight_completed":
      return "Flight Completed";
    case "on_option":
      return "On Option";
    case "lost":
      return "Lost";
    case true:
    case 1:
      return "Active";
    case false:
    case 0:
      return "Inactive";
    case "agent":
      return "Agent";
    case "consumer":
      return "Consumer";
    case "sales":
      return "JetClass";
    case "master":
      return "Master";
    case "regular":
      return "Limited";
    default:
      return "N/A";
  }
});

Vue.filter("color", function (status = "") {
  switch (status) {
    case "accepted":
    case "flight_confirmed":
    case "flight_completed":
    case true:
    case 1:
      return "success";
    case "cancelled":
    case "lost":
    case false:
    case 0:
      return "error";
    case "awaiting":
    case "awaiting_offers":
      return "waiting";
    case "expired":
      return "expired";
    case "working_on_offers":
      return "received";
    case "awaiting_feedback":
    case "on_option":
      return "awaitingFeedback";
    case "offer_confirmed":
      return "secondary";
    case "regular":
      return "default";
    case "agent":
    case "master":
    default:
      return "primary";
  }
});

Vue.filter("yesno", function (status = true) {
  switch (status) {
    default:
    case true:
      return "Yes";
    case false:
      return "No";
  }
});

Vue.filter("date", function (date = moment().toISOString(), type = "iso") {
  if (type === "iso") return moment(date).toISOString();
  else if (type === "ll") return moment(date).format("ll");
  return moment(date).toISOString();
});

// German locale de-DE
Vue.filter("number", (number, locale = "en-US") => Intl.NumberFormat(locale).format(number));

Vue.filter("currency", (amount, currency = "EUR", locale = "en-US", decimals = 2) =>
  Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
    maximumFractionDigits: decimals,
  }).format(amount)
);

Vue.filter("text", (string = "") => (string ? string : " - "));
Vue.filter("durationhm", (milliseconds = null) =>
  milliseconds ? `${moment.duration(milliseconds).hours()} h ${moment.duration(milliseconds).minutes()} mins` : " - "
);
Vue.filter("validateYear", (year) => (year === "0000" || year === 0 ? "-" : year));
