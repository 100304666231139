const initialState = () => ({
  enquiryBookmarks: [],
  offerBookmarks: [],
});

export default {
  state: initialState(),
  actions: {
    async toggleEnquiryBookmark({ commit }, payload = {}) {
      let res = await this._vm.$api.post("Bookmark", payload);
      if (res.status === 201) {
        commit("setEnquiryBookmarks", res.data.data);
      }
    },

    async toggleOfferBookmark({ commit }, payload = {}) {
      let res = await this._vm.$api.post("Bookmark", payload);
      if (res.status === 201) {
        commit("setOfferBookmarks", res.data.data);
      }
    },
  },
  mutations: {
    setEnquiryBookmarks(state, data) {
      state.enquiryBookmarks = data;
    },
    setOfferBookmarks(state, data) {
      state.offerBookmarks = data;
    },
    ResetBookmark(state) {
      const newState = initialState();
      Object.keys(newState).forEach((key) => {
        state[key] = newState[key];
      });
    },
  },

  getters: {
    getEnquiryBookmarks: (state) => state.enquiryBookmarks,
    getOfferBookmarks: (state) => state.offerBookmarks,
  },
};
