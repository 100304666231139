export default {
  default: "#F4F5F9",
  primary: "#32acc8",
  secondary: "#12e6d0",
  accent: "#1d2029",
  error: "#FF5252",
  info: "#2196F3",
  success: "#4CAF50",
  warning: "#FFC107",
  primaryText: "#424242",
  secondaryText: "#909090",
  dark: "#1D2029",
  received: "#FFE0DB",
  awaitingFeedback: "#ffbc41",
  waiting: "#FFEBA4",
  confirmed: "#12e6d0",
  grey1: "#999BAD",
  grey2: "#B6B8CB",
  grey3: "#C8CBE1",
  grey4: "#D8DAE9",
  grey5: "#F4F5F9",
  faded: "#C8CBE1",
  chatbg: "#E1F5F9",
};
