const initialState = () => ({
  notifierMessage: "",
  notifierType: "success",
});

export const NotifierStore = {
  state: initialState(),
  mutations: {
    setNotifier(state, payload) {
      state.notifierMessage = payload.message;
      state.notifierType = payload.type;
    },
  },
  getters: {
    getNotifier(state) {
      return { message: state.notifierMessage, type: state.notifierType };
    },
  },
};
